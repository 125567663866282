import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Layout from './shared/components/layout/Layout';
import SchoolNonAuthRoutes from './school/routes/School.NonAuth.Routes';
import SchoolHomePage from './school/pages/SchoolHome.Page';

function App() {
  return (
    <>
      {/* prettier-ignore */}
      <BrowserRouter>
        <Routes>
          <Route element={<><Layout /></>}>
            <Route path="*" element={<SchoolHomePage />} />
            <Route path="account/*" element={<>Some path</>} />
            <Route path="identity/*" element={<>Some path</>} />
            <Route path="school/*" element={<SchoolNonAuthRoutes />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
