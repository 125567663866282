import { Link } from 'react-router-dom';

export default function Middlebar() {
  return (
    <>
      <div className="mt-4 py-4 px-5 text-white gradient-bg">
        <h3>SPEED FORGE SCHOOL</h3>
        <p>
          Welcome to the school where useful materials are collected to improve passive safety skills and driving in general. <br />
          Particular attention is paid to traffic rules and the behavior of participants in general.
        </p>
        <Link className="btn btn-outline-info btn-sm" to="/school">
          HAVE A LOOK
        </Link>
      </div>
    </>
  );
}
