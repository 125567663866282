import { Route, Routes } from 'react-router-dom';
import SchoolHomePage from '../pages/SchoolHome.Page';
import CategoryPage from '../pages/Category.Page';
import LessonPage from '../pages/Lesson.Page';

export default function SchoolNonAuthRoutes() {
  return (
    <Routes>
      <Route path="*" element={<SchoolHomePage />}></Route>
      <Route path="/category/:categoryUrl/:pageIndex?/:pageSize?" element={<CategoryPage />} />
      <Route path="/view/:lessonUrl/" element={<LessonPage />} />
    </Routes>
  );
}
