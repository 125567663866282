export default function ContentLayoutContainer({ children }: { children: React.ReactNode }) {
  return (
    <>
      <div className=" h-100">
        <div className="container-fluid content-box">
          <div className="mt-3">{children}</div>
        </div>
      </div>
    </>
  );
}
