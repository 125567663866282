import { NavLink } from 'react-router-dom';

export default function Topbar() {
  return (
    <>
      <div className="row top-row gradient-row">
        <div className="col-3 position-relative">
          <div className="position-absolute top-50 start-50 translate-middle">
            <h1>
              SPEED <br /> FORGE
            </h1>
          </div>
        </div>
        <div className="col-9">
          <nav className="navbar navbar-expand-lg navbar-dark">
            <div className="container-fluid">
              <div className="collapse navbar-collapse justify-content-end" id="navbarNav">
                <ul className="navbar-nav">
                  <li className="nav-item">
                    <NavLink className="nav-link" aria-current="page" to="/">
                      Home
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink className="nav-link" aria-current="page" to="/school">
                      School
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
          <div className="navbar-underline"></div>
        </div>
      </div>
    </>
  );
}
