interface VideoProps {
  videoLink: string;
  width: string;
  height: string;
}

export default function YouTubeVideoPlayer({ videoLink, width, height }: VideoProps) {
  return (
    <div className="video-container">
      <iframe
        width={width}
        height={height}
        src={videoLink}
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerPolicy="strict-origin-when-cross-origin"
        allowFullScreen
      ></iframe>
    </div>
  );
}
