export default function Footer() {
  return (
    <div className="mt-5">
      <footer className="footer mt-auto py-3 rounded container-lg ">
        <div className="container-fluid content-box">
          <span className="text-dark">SpeedForge.uk © All right reserved</span>
        </div>
      </footer>
    </div>
  );
}
