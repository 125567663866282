import { useEffect, useState } from 'react';
import ContentLayoutContainer from '../../shared/components-ui/containers/ContentLayout.Container';
import { LessonDTO } from '../../shared/interfaces/school/Lesson.DTO';
import api from '../../shared/services/api';
import { useParams } from 'react-router-dom';
import DOMPurify from 'dompurify';
import YouTubeVideoPlayer from '../components/YuoTube.VideoPlayer';
import { Helmet, HelmetProvider } from 'react-helmet-async';

export default function LessonPage() {
  const { lessonUrl } = useParams();
  const [lesson, setLesson] = useState<LessonDTO | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      const response = await api.get(`SchoolRequest/GetLessonByUrl?lessonUrl=${lessonUrl}`);
      setLesson(response.data);
    };
    fetchData();
  }, []);

  if (!lesson) {
    return <>no data</>;
  }

  return (
    <ContentLayoutContainer>
      <HelmetProvider>
        <div>
          <Helmet>
            <title>{lesson.name}</title>
            <link rel="canonical" href={`http://speedforge.uk/school/view/${lesson.url}`} />
          </Helmet>
        </div>
      </HelmetProvider>

      <h1>{lesson.name}</h1>
      {lesson.isVideoPresentation ? (
        <div className="video-container">
          <YouTubeVideoPlayer videoLink={lesson.videoPresentationLink} width={'100%'} height={'650'} />
        </div>
      ) : (
        <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(lesson.presentation) }}></p>
      )}
      <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(lesson.description) }}></p>
    </ContentLayoutContainer>
  );
}
