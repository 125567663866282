import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CategoryDTO } from '../../shared/interfaces/school/Category.DTO';
import api from '../../shared/services/api';
import ContentLayoutContainer from '../../shared/components-ui/containers/ContentLayout.Container';
import CategoryList from '../components/CategoryList';
import { CategoryLessonList } from '../components/CategoryLesson.List';

export default function CategoryPage() {
  const { categoryUrl, pageIndex, pageSize } = useParams();
  const [category, setCategory] = useState<CategoryDTO | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      const response = await api.get(`SchoolRequest/GetCategoryByUrl?url=${categoryUrl}`);
      setCategory(response.data);
    };
    fetchData();
  }, [categoryUrl]);

  if (!category) {
    return <>no data</>;
  }

  return (
    <ContentLayoutContainer>
      <div className="row">
        <div className="col-9">
          <CategoryLessonList
            lessonProp={{
              categoryId: category.id,
              pageIndex: pageIndex ? parseInt(pageIndex, 10) : 0,
              pageSize: pageSize ? parseInt(pageSize, 10) : 10,
            }}
          />
        </div>
        <div className="col-3">
          <CategoryList />
        </div>
      </div>
    </ContentLayoutContainer>
  );
}
