import { useEffect, useState } from 'react';
import ContentLayoutContainer from '../../shared/components-ui/containers/ContentLayout.Container';
import { CategoryDTO } from '../../shared/interfaces/school/Category.DTO';
import api from '../../shared/services/api';
import CategoryList from '../components/CategoryList';
import LessonList from '../components/Lesson.List';

export default function SchoolHomePage() {
  return (
    <ContentLayoutContainer>
      <div className="row">
        <div className="col-9">
          <LessonList />
        </div>
        <div className="col-3">
          <CategoryList />
        </div>
      </div>
    </ContentLayoutContainer>
  );
}
