import { configureStore, createSlice } from '@reduxjs/toolkit';

// Заглушка слайса
const counterSlice = createSlice({
  name: 'counter',
  initialState: {},
  reducers: {},
});

const store = configureStore({
  reducer: {
    counter: counterSlice.reducer, // Временная заглушка
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
