import Middlebar from './middle-bar/Middle.bar';
import Topbar from './top-bar/Top.bar';

export default function Header() {
  return (
    <header>
      <div className="top-container-background">
        <div className="container-fluid container-fluid-top">
          <Topbar />
          <Middlebar />
        </div>
      </div>
      <div className="middle-line"> &nbsp;</div>
    </header>
  );
}
